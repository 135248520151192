
@use '@/styles/styles.scss' as lib;

@include lib.d_block;
@include lib.d_md_block;
@include lib.d_md_none;
@include lib.d_none;
@include lib.text_white;

@include lib.h1;
@include lib.mb_0;
@include lib.ml_1;
@include lib.mt_1;

@include lib.shadow_well;
@include lib.py_4;
@include lib.py_2;

.bg-white {
  background-color: lib.$white;
}

