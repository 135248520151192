
@use '@/styles/styles' as lib;

@include lib.mb_1;
@include lib.py_5;
@include lib.pt_5;

#form-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem 1rem 1.5rem 1rem;
}

#form-footer {
  display: flex;
  gap: 1rem;
}

.input-field {
  width: 320px;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-button-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
