
@use '@/styles/styles' as lib;

@include lib.mb_1;
@include lib.mt_1;

.percentage-input {
  width: 90px;
}

