
@use '@/styles/styles' as lib;

@include lib.d_flex;
@include lib.justify_content_between;
@include lib.align_items_center;
@include lib.p_2;
@include lib.ml_2;
@include lib.my_3;
@include lib.m_3;

#action-bar {
  border-bottom: 1px solid lib.$n65;
  padding: 1rem;
}

.button-spacing-no-filter {
  margin-left: 1rem;
}

#search-input input{
  width: 270px;
}

