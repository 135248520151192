
@use '@/styles/styles.scss' as lib;

.dark {
  color: lib.$n670;
}

.green {
  composes: lib.$g400;
}

.gray {
  composes: lib.$n300;
}

.lightGray {
  composes: lib.$n100;
}

.light {
  composes: lib.$n50;
}

.dashed {
  border-top: 1px dashed;
  border-bottom: none;
}

.dotted {
  border-top: 1px dotted;
  border-bottom: none;
}

.double {
  border-top: 3px double;
  border-bottom: none;
}

