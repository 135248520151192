
@use '@/styles/styles' as lib;

@include lib.align_items_center;
@include lib.d_flex;
tr {
  &:hover {
    cursor: pointer;
    background-color: lib.$g50;
  }
  td {
    border-bottom: 1px solid lib.$n65;
    padding: 0.75rem;
    vertical-align: top;
  }
}

.center-align {
  text-align: center;
  vertical-align: middle;
}

.inline {
  display: inline;
}

.right-align {
  text-align: right;
}
