
@use '@/styles/styles.scss' as lib;

@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.pt_3;
@include lib.pt_5;
@include lib.pb-6;
@include lib.pb-lg-7;

.banner-container {
  background-color: lib.$gm55;
  grid-template-columns: [padding-left] 1fr [form-hero] 6fr [padding-right] 1fr ;
  display: grid;

  @media (max-width: 920px) {
    grid-template-columns: [padding-left] 0fr [form-hero] 8fr [padding-right] 0fr ;
  }

  .form-hero {
    grid-column: form-hero / padding-right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-long {
    display: block;

    @media (max-width: 920px) {
      display: none;
    }
  }
  .title-short {
    display: none;

    @media (max-width: 920px) {
      display: block;
    }
  }
}

.inner-page-container {
  display: grid;
  grid-template-columns: [padding-left] 1fr [content] 4fr [padding-right] 3fr;

  @media (max-width: 920px) {
    grid-template-columns: [padding-left] 0fr [content] 8fr [padding-right] 0fr ;
  }

  .content {
    grid-column: content / padding-right;
  }
}
