
@use '@/styles/styles.scss' as lib;

@include lib.col_12;
@include lib.container_fluid;
@include lib.d_block;
@include lib.d_md_block;
@include lib.d_md_none;
@include lib.d_none;
@include lib.text_white;

@include lib.h2;
@include lib.mb_0;
@include lib.mb_1;
@include lib.ml_1;
@include lib.mt_1;
@include lib.px_0;
@include lib.px_md_3;
@include lib.py_3;
@include lib.py_5;
@include lib.row;
@include lib.sml_md__1;
@include lib.spx_md_2;

@media (max-width: 768px){
  .d-none-max-md {
    display: none;
  }
}

