
ul {
  list-style-type: none;
  padding: 0;
}

// to accommodate accordion chevron space
#header {
  margin-right: 40px;
}

#error {
  width: 25%;
  margin: auto;
}
