
@use '../../styles/styles.scss' as lib;

@include lib.mr-2;
@include lib.mt-2;
@include lib.row;
@include lib.d-flex;
@include lib.justify_content_between;
@include lib.col_6;
@include lib.col_sm-12;

.controls {
  display: flex;
  justify-content: flex-start;
}

.form-name-container {
  display: flex;
  justify-content: space-between;
  gap: 16px
}

.form-name {
  flex-grow: 1;
}

.form {
  width: 500px;
}
