
@use '../../styles/styles.scss' as lib;

@include lib.mb-2;
@include lib.ml-2;

.controls {
  display: flex;
  justify-content: flex-end;
}

.footer {
  display: flex;
  justify-content: space-between;
}

