
@use '@/styles/styles.scss' as lib;

@include lib.container_fluid;
@include lib.em_page_grid;
@include lib.d_flex;
@include lib.flex_column;
@include lib.flex_grow_1;
@include lib.row;
@include lib.col;
@include lib.col_lg_7;
@include lib.col_lg_3;
@include lib.pb-6;
@include lib.pb-lg-7;
@include lib.px-0;
@include lib.pt-1;
@include lib.pt-lg-0;
@include lib.pt-lg-2;
@include lib.position_relative;
@include lib.mx_0;
@include lib.overflow_show;
@include lib.position_absolute;

.page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
