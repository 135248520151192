
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;

.column-container {
  display: flex;
  flex-direction: column;
}

.content-item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;

  :deep(button) {
    padding-left: 0;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  }

  &.matching-categories {
    width: fit-content;
    margin-top: 0.5rem;

    input[type='checkbox'] {
      margin-top: 5px;
      margin-bottom: -5px;
      margin-right: 0;
    }
  }

  &.matching-categories-title {
    width: fit-content;
    margin-top: 1rem;
  }
}

.filterInput {
  width: 100%;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
}
