
@use '@/styles/styles.scss' as lib;

@include lib.d_flex;
@include lib.justify_content_start;
@include lib.justify_content_between;
@include lib.mb_2;

.label-column {
  flex-basis: 10%;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

tr {
  td, th {
    padding: 0.75rem 1rem 0.75rem 1rem;
    vertical-align: top;
    overflow-wrap: break-word;
    border: 1px solid lib.$n65;
  }
  td.account-cell div:not(:first-child) {
    padding: 1.5rem 0 0 0;
  }
}
