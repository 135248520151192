
@use '@/styles/styles.scss' as lib;

@include lib.col;
@include lib.d_block;
@include lib.flex_fill;
@include lib.float_right;
@include lib.m_0;
@include lib.ml_3;
@include lib.mt_0;
@include lib.row;

.header-profile {
  min-width: 0;

  .header-profile-left {
    min-width: 0;
  }
}
