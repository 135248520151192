
@use '@/styles/styles.scss' as lib;

@include lib.mt_0;
@include lib.mb_1;

.panel-footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 1em;

  .button {
    flex-grow: 1;
  }
}

