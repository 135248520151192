
@use '@/styles/styles.scss' as lib;

@include lib.p;
@include lib.label;
@include lib.h4;
@include lib.text_intro;
@include lib.text_small;
@include lib.text_green;
@include lib.text_gray;
@include lib.text_light_gray;
@include lib.text_white;

.light-green {
  color: lib.$g200;
}

.margin-0 {
  margin: 0;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.bold {
  font-weight: 700;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: 0.1s color;
  color: lib.$info300;

  &:hover {
    text-decoration: underline;
  }
}
