
@use '@/styles/styles.scss' as lib;

@include lib.p_3;

.header-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

.header-btn-container {
  display: flex;
  gap: 1rem;
}

.section {
  width:33%;
}

.section h3 {
  margin-bottom: 16px;
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;
  position: relative;
}

  .shares-section {
    display: flex;
    justify-content: space-between;
  }

.icon {
  vertical-align: middle;
  line-height: 1;
}

.shares-group {
  margin-bottom: 1rem;
}

.shares-group p {
  margin-bottom: 0;
}

.typology-container {
  margin-top:  0;
}

.standard-layout {
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  flex-direction: column;
  margin-bottom: 1rem;
}

.warning-status {
  padding: 2px 8px;
}

.margin-bottom {
  margin-bottom: 1rem;
}

#pencil-button {
  display: inline;
  cursor: pointer;
  margin-right: 8px;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

#modalTitle {
  padding-right: 2rem;
}

#modal-error {
  margin-top: 1rem;
}

input {
  text-align: right;
}

