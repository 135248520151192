
@use '@/styles/styles.scss' as lib;

@include lib.mt_3;

@media (min-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas:
    'progress topTen'
    'graphPerson graphSecurities';
  }
}

@media (max-width: 1000px) {
  .container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
    'progress'
    'graphPerson'
    'graphSecurities'
    'topTen';
  }
}

.container {
  gap: 16px;
  margin-top: 24px;
}

.progressArea {
  grid-area: progress;
}

.graphPersons {
  grid-area: graphPerson;
}

.graphSecurities {
  grid-area: graphSecurities;
}

.topTen {
  grid-area: topTen;
}

.containerItem {
  background-color: lib.$white;
  padding: 24px;
  width: 100%
}

