
@use '@/styles/styles.scss' as lib;

@include lib.align_items_center;
@include lib.container_fluid;
@include lib.d_flex;
@include lib.em_page_grid;
@include lib.flex_wrap;
@include lib.flex_row;
@include lib.justify_content_center;
@include lib.justify_content_between;
@include lib.mt_0;
@include lib.mb_0;
@include lib.overflow_show;
@include lib.p_0;
@include lib.px_0;

.header-menu {
  background-color: lib.$gm50;

  a:hover {
    text-decoration: none;
  }

  .header-menu-container {
    width: 100%;
    height: 100%;
    list-style: none;
  }

  .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-height: 48px;
  }

  @media (min-width: 768px) {
    .header-menu-container {
      width: auto;

      .header-menu-item {
        a {
          line-height: 24px;
          font-size: 16px;
        }

        .header-menu-link {
          padding: 12px 12px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    .container-fluid {
      max-height: 64px !important;
    }

    .container-fluid ul li .header-menu-link {
      height: 64px !important;
    }
  }

  @media (min-width: 992px) {
    .header-menu-container {
      padding-left: 90px !important;

      .header-menu-item {
        .header-menu-link {
          padding: 12px 16px;
        }
      }
    }
  }
}

