
@use '@/styles/styles.scss' as lib;

@include lib.pt_1;

.title-pre-selected-items {
  padding: 0 1rem 0.75rem 1rem;
}

.title-selected-items {
  padding: 0.75rem 1rem 0.75rem 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
