
@use '../../styles/styles.scss' as lib;

@include lib.col;
@include lib.mx-2;
@include lib.px-2;
@include lib.pt-2;
@include lib.mt-2;
@include lib.row;

.footer {
  padding: 24px 16px 24px 16px;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: lib.$gm55;
}

.set-password-tile {
  width: 450px;
}
