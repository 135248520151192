
@use '../../styles/styles.scss' as lib;

@include lib.shadow_tile;
@include lib.rounded_2;

.footer {
  background-color: lib.$gm55;
}

