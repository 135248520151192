
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;

#modal-footer {
  display: flex;
  justify-content: flex-end;
}

