
@use '@/styles/styles.scss' as lib;

@include lib.align_items_center;
@include lib.d_flex;
@include lib.col_1;
@include lib.col_11;
@include lib.text_center;
@include lib.no_gutters;
@include lib.justify_content_center;
@include lib.row;
@include lib.pl_1;
@include lib.m_0;

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

tr:has(th) {
  &.grid {
    border: 1px solid lib.$n65;
  }

  &.horizontalOnly {
    border-bottom: 1px solid lib.$n65;
  }

  th {
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
}

tr:has(td) {
  &.disabled {
    opacity: 0.33;
  }
  &:hover.selectable {
    cursor: pointer;
    background-color: lib.$g50;
  }
  &.selected{
    background-color: lib.$gm55;
  }
  &.bar, &.grid {
    border: 1px solid lib.$n65;
  }
  &.horizontalOnly:not(.accordion-open) {
    border-bottom: 1px solid lib.$n65;
  }
}

tr {
  td {
    padding: 0.75rem 1rem 0.75rem 1rem;
    vertical-align: top;
    overflow-wrap: break-word;

    &:hover.removable {
      cursor: pointer;
      background-color: lib.$g50;
    }
  }
}

.title-cell {
  padding: 0 1rem 0 1rem;
}

.header-content {
  display: flex;
  align-items: center;
  &.amount,
  &.amountOfAmount {
    flex-direction: row-reverse;
  }
}

.header-amount,
.header-amountOfAmount {
  justify-content: end;
}

.header-checkbox {
  justify-content: center;
  vertical-align: middle;
}

.sortable-label:hover, {
  text-decoration: underline;
  cursor: pointer;
  color: lib.$g300;
}

.info-popover {
  margin-left: 8px;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.width40 {
  width: 40%;
}

.width45 {
  width: 45%;
}

.sort-icon {
  transform: rotate( 0deg );
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}
.sort-icon.rotate {
  transform: rotate(-180deg);
  transition: all .2s cubic-bezier(0.4, 0, 0.2, 1);
}

.checkbox {
  border-right: none;
  margin-right: 0;
  text-align: center;
  vertical-align: middle;
}

.center-align {
  vertical-align: middle;
}

.title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.center-block {
  margin: 0;
}

.amount,
.amountOfAmount {
  text-align: right;
}

.icon-rotate {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.expand {
  transform: rotate(-180deg);
}

.accordion-enter-active,
.accordion-leave-active {
  transition: line-height 0.3s ease;
}

.accordion-enter-from,
.accordion-leave-to {
  line-height: 0;
}

