
@use '@/styles/styles.scss' as lib;

@include lib.h2;
@include lib.mt_2;
@include lib.mx_2;
@include lib.mx_3;
@include lib.p_2;
@include lib.pt_3;
@include lib.d_flex;
@include lib.justify_content_start;

#new-password-form {
  width: 450px;
  flex-shrink: 0;
}

.form-element {
  width: 100%;
}

.footer {
  padding: 24px 16px 24px 16px;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

