
@use '@/styles/styles.scss' as lib;

@include lib.col;
@include lib.container_fluid;
@include lib.em_page_grid;
@include lib.mx_0;
@include lib.overflow_show;
@include lib.px-0;
@include lib.pt-1;
@include lib.pt-lg-0;
@include lib.pt-lg-2;
@include lib.row;

.bg-white {
  background-color: lib.$white;
}

header {
  .em-header {
    min-height: 72px;
  }

  .header-wrapper a:hover {
    color: lib.$g200;
    text-decoration: none;
  }

  .header-wrapper a:active {
    color: lib.$g500;
    text-decoration: none;
  }

  .em-brand {
    height: 44px;
    width: 32px;
    background-size: 176px 44px;
    background-repeat: no-repeat;
  }
}
