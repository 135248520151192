
@use '@/styles/styles.scss' as lib;

@include lib.position_relative;

.dropdown {
  top: 100%;
  max-height: 220px;
  outline: none;
  background-color: #fff;
  z-index: 400;
  list-style-type: none;
  width: 100%;
  left: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  box-shadow: 0 8px 16px rgba(34, 34, 34, 0.3), 0 0 8px rgba(34, 34, 34, 0.0224185);
  overflow: auto;
}

.option {
  line-height: 24px;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 16px 8px 16px;
}

.option:hover {
  background-color: lib.$g55;
}

input {
  width: 270px;
}

#searchIcon {
  &:hover {
    cursor: pointer;
  }
}

