
@use '@/styles/styles.scss' as lib;

@include lib.border_box;
@include lib.p_1;
@include lib.pl_3;
@include lib.py_1;
@include lib.p_0;
@include lib.m_0;
@include lib.float_right;
@include lib.d_inline_block;
@include lib.d_flex;
@include lib.flex_grow_1;
@include lib.h4;

.background {
  background-color: lib.$g50;
}

h4 {
  color: lib.$gm400;
}
