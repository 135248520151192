
@use '@/styles/styles.scss' as lib;

.table-column {
  flex-basis: 15%;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

#error {
  width: 25%;
  margin: auto;
}

