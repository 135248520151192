
@use '@/styles/styles.scss' as lib;

@include lib.mr_2;
@include lib.mt_2;
@include lib.mb_1;

#filterContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.containerItem {
  display: flex;
  align-items: flex-end;
}

.column-container {
  display: flex;
  flex-direction: column;
}

.content-item {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
}

.filterInput {
  width: 100%;
}

#modalFooter {
  display: flex;
  justify-content: flex-end;
}

#searchInput {
  width: 300px;
}
