
@use '@/styles/styles.scss' as lib;

@include lib.border_box;
@include lib.p_1;
@include lib.pl_3;
@include lib.py_1;
@include lib.p_0;
@include lib.m_0;
@include lib.float_right;
@include lib.d_inline_block;
@include lib.d_flex;
@include lib.flex_grow_1;
@include lib.h4;

li {
  cursor: pointer;
  font-size: 1rem;
  list-style: none;
  line-height: 24px;
  margin: 0;
}

ul.subLink {
  padding: 0;
}

li.subLink a {
  padding: 8px 8px 8px 40px;
}

a:hover,
a.router-link-active {
  background-color: lib.$gm50;
  font-weight: bold;
}

.bordered-items a:hover,
.bordered-items a.router-link-active {
  border-left: 2px solid lib.$gm65;
}

.chevron {
  vertical-align: sub;
}

a {
  border-left: 2px solid transparent;
  text-decoration: none;
  color: lib.$g300;
  display: flex;
  justify-content: space-between;
}
