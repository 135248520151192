
@use '@/styles/styles' as lib;
@include lib.p_3;
@include lib.pb_2;
@include lib.mt_3;

.divider-vertical {
  width: 1px;
  background-color: lib.$n65;
}

.divider-horizontal {
  height: 1px;
  margin: 1rem 0;
  background-color: lib.$n65;
}
