
@use '@/styles/styles.scss' as lib;

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.header-email-frame {
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 14px;
  border: 1px solid lib.$n65;
  margin: -1px;
}

.iframe-modal {
  position: relative;
  width: 100%;
  height: 100vh;
  border: 1px solid lib.$n65;
}
