
@use '@/styles/styles' as lib;

@include lib.d_flex;
@include lib.justify_content_between;
@include lib.pb_1;
@include lib.pb_2;
@include lib.p_2;
@include lib.mb_2;

.summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  &.title {
    align-items: center;
  }
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  &.progress {
    flex-grow: 1;
  }
  &.number {
    align-items: flex-end;
  }
}

